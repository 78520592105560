<template>
  <div class="home" style="height: 60vh; width: 100vw">
    <v-main align="center" class="fill-height mt-0 pt-5" row wrap style="width: 100%">
      <v-row v-if="!loading" justify="start" align="center">
        <v-col
            cols="10"
            offset="1"
            sm="8"
            offset-sm="2"
            md="6"
            offset-md="3"
            lg="6"
            offset-lg="3"
            class="pa-0"
        >
          <span v-if="$i18n.locale=='de'"
              class="justify-center canary-title white--text" style="width: 100%">
                Schritt 1: Geben Sie bitte an, für welche Gesellschaft der JB-Gruppe Sie tätig sind.
          </span>
          <span v-else class="justify-center canary-title white--text" style="width: 100%">
                Step 1: Please select the JB Group entity you work for
          </span>
        </v-col>
        <v-col cols="3"></v-col>
      </v-row>
      <v-row class="mt-12">
        <v-col
            cols="10"
            offset="1"
            sm="8"
            offset-sm="2"
            md="6"
            offset-md="3"
            lg="6"
            offset-lg="3"
            class="pa-0"
        >
          <v-card width="100%" class="fill-height d-flex flex-column py-6" flat tile>
            <v-card-title class="justify-center primary--text text-center font-weight-bold pb-0">
              <span v-if="$i18n.locale=='de'">
                Alle Gesellschaften der Julius Bär Gruppe AG
              </span>
              <span v-else>
                Any Entity of Julius Baer Group Ltd.
              </span>
            </v-card-title>
            <v-card-text class="primary--text pb-0">
              <span v-if="$i18n.locale=='de'">
                (einschließlich der Bank Julius Bär & Co. AG, mit Ausnahme der zwei unten aufgeführten Gesellschaften)
              </span>
              <span v-else>
                (including Bank Julius Baer & Co. Ltd, to the exception of the three entities listed below)
              </span>
            </v-card-text>
            <v-card-text class="primary--text text-center">
              -<br/><br/>
              <span v-if="$i18n.locale=='de'">
                Weltweit
              </span>
              <span v-else>
                Global
              </span>
            </v-card-text>
            <v-spacer></v-spacer>
            <v-card-text class="justify-center d-flex pb-0">
              <v-btn small depressed color="primary" to="/global">{{ $t('labelContinue') }}</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-12">
        <v-col
            cols="10"
            offset="1"
            sm="8"
            offset-sm="2"
            md="6"
            offset-md="3"
            lg="6"
            offset-lg="3"
            class="pa-0"
        >
          <v-row class="pa-0 ma-0">
            <v-col class="pa-0 " cols="6">
              <v-row class="pa-0 ma-0 fill-height mr-2">
                <v-col cols="12" class="pa-0 fill-height">
                  <v-card flat tile class="fill-height d-flex flex-column">
                    <v-card-title class="justify-center primary--text text-center font-weight-bold">
                      Bank Julius Bär Deutschland AG
                    </v-card-title>
                    <v-card-text class="primary--text text-center">
                      -<br/><br/>
                      <span v-if="$i18n.locale=='de'">
                        Deutschland
                      </span>
                      <span v-else>
                        Germany
                      </span>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-text class="justify-center d-flex pb-5">
                      <v-btn small depressed color="primary" to="/addressee-selection/de">{{ $t('labelContinue') }}</v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="pa-0 " cols="6">
              <v-row class="pa-0 ma-0 justify-end ml-2">
                <v-col cols="12" class="pa-0">
                  <v-card flat tile class="d-flex flex-column">
                    <v-card-title class="justify-center primary--text text-center font-weight-bold">
                      Bank Julius Baer Europe S.A.
                    </v-card-title>
                    <v-card-text class="primary--text text-center">
                      -<br/><br/>
                      <span v-if="$i18n.locale=='de'">
                        Luxemburg<br/>
                        (einschließlich der Standorte in Spanien, Irland und Italien)
                      </span>
                      <span v-else>
                        Luxembourg<br/>
                        (including Spain, Ireland and Italy Branches)
                      </span>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-text class="d-flex justify-center pb-5 pt-0">
                      <v-btn small depressed color="primary" to="/addressee-selection/lu">{{ $t('labelContinue') }}</v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-main>
  </div>
</template>

<script>
export default {
  name: "Step1"
}
</script>

<style scoped>
  .home {
    width: 100%;
  }
</style>